import React, { useState } from 'react';
import { Form, Spinner, Button, Badge } from 'react-bootstrap';
import auth from '../Account/Auth';
import be from '../BE';

const MemberRow = ({ canUpgrade, member, canPromoteList, viewerRole, removeMember, selected, handleMemberSelection }) => {
  const [selectedRole, setSelectedRole] = useState(member.role);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeRole = async (newRole) => {
    setIsLoading(true);
    const body = {
      key: member.key,
      role: newRole,
    };
    try {
      await auth.isAuthenticated()
        .then(async () => {
          await be.post("team-manager","/team/members/roles",body,true,true)
            .then((response) => {setSelectedRole(newRole)})
            .catch((response) => {console.error('[team] error changing user role: ',response)})
        })
        .catch(()=>{})
    } catch (error) {
      console.error('Error updating member role:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const canChangeRole = () => {
    if (member?.status == "pending") {return false}
    if(auth.getUsername() !== member?.key){
      if(member?.role !== 'owner'){
        if((viewerRole === 'owner' || viewerRole === 'admin') && canPromoteList.length > 0){
          return true
        }else{return false}
      }else{return false}
    }else{return false}
  }

  const getPlanColor = (_role) => {
    switch(_role){
      case "LTD": return "success";
      case "professional": return "primary";
      case "premium": return "warning";
      case "free": return "info";
      default: return "secondary"
    }
  }

  return (
    <tr>

      {/*--- user info ---*/}
      <td>
        <div className="d-flex align-items-center">
          <div className="d-inline-flex align-items-center justify-content-center me-2">
          
          {member?.role !== 'owner' ? (
            <Form.Check disabled={false} data-key={member?.key} data-plan={member?.plan} checked={selected} onChange={handleMemberSelection}></Form.Check>
          ) : null }
            </div>
          <div className="d-inline-flex align-center me-2">
            <img width="38px" height="38px" src={member?.pic} alt="profile-pic" className="rounded-circle" style={{border:"3px solid var(--color-border-light)"}}/>
          </div>
          <div className="d-inline-block">
            <div>
              {member?.name}
            </div>
            <div className="text-secondary">
              {member?.email}
            </div>
          </div>
        </div>
      </td>

      {/*--- plan ---*/}
      <td className="text-capitalize text-center align-middle">
        <Badge bg={getPlanColor(member?.plan)}> {member?.plan} </Badge>
      </td>

      {/*--- change/view role ---*/}
      <td className="text-center align-middle">
        {isLoading && <Spinner as="span" animation="border" size="sm" />}
        {canChangeRole() ? (
          <Form.Select
            className="text-center"
            aria-label="Select role"
            disabled={isLoading}
            value={selectedRole}
            onChange={(e) => handleChangeRole(e.target.value)}
            style={{color:'var(--color-text)',backgroundColor:'var(--color-background-2)',borderColor:'var(--color-border-light)',
            '--bs-form-select-bg-img':`url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")`}}
          >
            {canPromoteList.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </Form.Select>
        ) : (
          member?.role
        )}
      </td>

      {/*--- connection status ---*/}
      <td className="text-center align-middle">
        {member?.status === 'connected' 
          ? <i className="fas fa-check-circle text-success me-2"></i>
          : <i className="fas fa-times-circle text-warning me-2"></i>
        }
        {member?.status}
      </td>

      {/*--- remove member ---*/}
      {(member?.role !== 'owner') && (viewerRole === 'owner' || viewerRole === 'admin') &&
        <td className="text-center align-middle">
          <button style={{border:'none',background:'transparent'}} onClick={() => removeMember(member?.key)}>
            <i class="fas fa-user-minus text-danger me-2"></i>
          </button>
        </td>
      }
    </tr>
  );
};

export default MemberRow;
