import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../store';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';
import './event-creator.css';
import be from '../BE';
import auth from '../Account/Auth';
import SaveBar from './SaveBar';
import General from './General/_General';
import Availability from './Availability/_Availability';
import Notifications from './Notifications/_Notifications';
import Surveys from './Surveys/_Surveys';
import Payments from './Payments/Payments';
import RoundRobin from './RoundRobin/RoundRobin';
import GeneralMock from '../Mocks/EventTypeCreator/General';
import AvailabilityMock from '../Mocks/EventTypeCreator/Availability';
import NotificationsMock from '../Mocks/EventTypeCreator/Notifications';
import RoundRobinMock from '../Mocks/EventTypeCreator/RoundRobin';
import ReactGA from "react-ga4";

function EventTypeCreator (props) {
  const history = useHistory();
  // If i'm editing an event type i get an event type key
  // I'm using this key to get the info from backend
  // So i can edit thise info and save the updated event type
  const eventTypeKey = props.location.state?.id
  if(eventTypeKey){ReactGA.send({ hitType: "pageview", page: "/eventeditor", title: "Event Type Editor" });}
  else{ReactGA.send({ hitType: "pageview", page: "/eventcreator", title: "Event Type Creator" });}
  try{window.tidioChatApi.show()}catch{}
  const {t} = useTranslation(['eventCreator']);
  const editKey = useCreateEventTypeStore(state => state.editKey);
  const setEditKey = useCreateEventTypeStore(state => state.setEditKey);
  const [existingQuestions,setExistingQuestions] = useState([])
  const [existingFields,setExistingFields] = useState([])
  const [loading,setLoading] = useState(false);
  const [saving,setSaving] = useState(false);
  const general = useCreateEventTypeStore(state => state.general);
  const availability = useCreateEventTypeStore(state => state.availability);
  const notifications = useCreateEventTypeStore(state => state.notifications);
  const surveys = useCreateEventTypeStore(state => state.surveys);
  const roundRobin = useCreateEventTypeStore(state => state.roundRobin);
  const paidEvent = useCreateEventTypeStore(state => state.paidEvent);
  const setGeneral = useCreateEventTypeStore((state) => state.setGeneral);
  const setAvailability = useCreateEventTypeStore((state) => state.setAvailability);
  const setNotifications = useCreateEventTypeStore((state) => state.setNotifications);
  const setSurveys = useCreateEventTypeStore((state) => state.setSurveys);
  const setPaidEvent = useCreateEventTypeStore((state) => state.setPaidEvent);
  const setRoundRobin = useCreateEventTypeStore((state) => state.setRoundRobin);

  useEffect(() => {
    const loadEventType = async (_key) => {
      setLoading(true);
      if(_key){
        // chiedo al backend le info sull'etype da modificare
        const eventTypeBody = {key : _key};
        const surveyBody = {etype : _key}
        await auth.isAuthenticated()
          .then(async () => {
            await be.post('eventtypes','/etype',eventTypeBody,true)
              .then(response => {
                setGeneral(response?.general);
                setAvailability(response?.availability);
                setNotifications(response?.advanced);
                setPaidEvent(response?.paidEvent);
                setRoundRobin(response?.roundRobin);
              });
          })
          .then(async () => {
            await be.get('survey','/getSurveys',surveyBody,true)
              .then(response => {
                setSurveys(response)
                // salvo le domande che erano già in be
                for (let id in response){
                  let tempQuestions = existingQuestions
                  tempQuestions.push(id)
                  setExistingQuestions(tempQuestions)
                  // salvo gli eventuali fields che erano già in be
                  for (let field in response[id]['qstFields']){
                    let tempFields = existingFields
                    tempFields.push(field)
                    setExistingFields(tempFields)
                  }
                }
                for (let id in response){
                  let temp = existingQuestions
                  temp.push(id)
                  setExistingQuestions(temp)
                }
              });
          })
        // redirect istantaneo alla pagina login se non sono autenticato
          .catch(() => props.history.push('/login'));
      } else {
        //carico il mock standard
        setGeneral(GeneralMock);
        setAvailability(AvailabilityMock);
        setNotifications(NotificationsMock);
        setSurveys({});
        setPaidEvent({});
        setRoundRobin(RoundRobinMock)
      }
      setLoading(false);
    }
    const eventTypeKey = props?.location?.state?.id
    eventTypeKey ? setEditKey(eventTypeKey) : setEditKey("")
    loadEventType(eventTypeKey);
  },[props, setLoading, setEditKey, setGeneral, setAvailability, setNotifications, setSurveys, existingQuestions, setExistingQuestions, existingFields, setExistingFields]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true)

    const eventTypeBody = {
      general : general,
      availability : availability,
      advanced : notifications,
      editkey : editKey,
      paidEvent : paidEvent,
      roundRobin: roundRobin
    }

    let surveyBody = {}
    surveyBody.surveys = surveys

    const hasSurveys = Object.keys(surveyBody.surveys).length !== 0

    await auth.isAuthenticated()
      .then(async () => {
        // general, availability, notifications
        await be.post('eventtypes','/eventypes',eventTypeBody,true)
          .then(response => {surveyBody.etype = response.etype})
          .then(() => !hasSurveys && props.history.push('/'))
          .catch(error => alert('Error Saving Event Type: ' + error));
        // surveys (save only if the obj is not empty or in edit mode)
        if(hasSurveys || editKey !== ''){
          await be.post('survey','/survey',surveyBody,true)
            .then(response => {})
            .then(() => history.push('/'))
            .catch(error => alert('Error Saving Questions: ' + error));
        }
      })
    // redirect istantaneo alla pagina login se non sono autenticato
      .catch(() => props.history.push('/login'));
    setSaving(false);
  }

  return (
    <>
      <Alert className="text-center" variant="primary">
        Great News! From now you can earn with your bookings!
        <span
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          className="mx-2"
          onClick={() => {
            try {
              // Attempt to find the element with the ID 'payments'
              const paymentsElement = document.getElementById('payments');
              // Check if the element exists before trying to scroll
              if (paymentsElement) {
                paymentsElement.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll for better UX
              } else {
                console.warn("Element with ID 'payments' not found."); // Log a warning for debugging
              }
            } catch (error) {
              // Catch any unexpected errors that might occur
              console.error("An error occurred while attempting to scroll:", error);
            }
          }}
        >
          Check it out!
        </span>
      </Alert>
      <Row>
        {eventTypeKey
          ?
            <span className="h3 text-center col mt-3">
              {t('mode.edit')} {eventTypeKey}
            </span>
            :
            <span className="h3 text-center col mt-3">
              {t('mode.create')}
            </span>
        }
      </Row>
      {loading
        ?
          <div className="text-center">
            LOADING...
          </div>
          :
          <>


            <Form 
              id="event-creator"
              onSubmit={handleSubmit}
              className="col-lg-9 col-sm-12 mx-auto mt-3 mb-3 pt-3 pb-3"
              style={{background:"transparent"}}
            >
              <SaveBar backButton saving={saving}/>

              <General/>

              <RoundRobin/>

              <Payments/>

              { general?.bookingType !== "round_robin" && 
              <Availability/>
              }

              <Notifications/>

              <Surveys 
                existingQuestions={existingQuestions}
                existingFields={existingFields}
                editing={editKey === '' ? false : true}
              />

              <SaveBar saving={saving}/>

            </Form>  
          </>
      }

    </>
  );
}

export default EventTypeCreator;
