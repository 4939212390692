import {useEffect, useState} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {useCreateBookingStore} from '../store';
import { useMediaQuery } from 'react-responsive'
import {useHistory} from 'react-router-dom';
import Details from './Details/Details';
import DatePicker from './DatePicker/DatePicker';
import TimezonePicker from './TimezonePicker/TimezonePicker';
import TimePicker from './TimePicker/TimePicker';
import Summary from './Summary/Summary';
import {isIframe} from '../Utilities/iframeUtils';
import be from '../BE';
import auth from '../Account/Auth';
import './booking.css';
import ReactGA from "react-ga4";

function Booking(props){
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `Booking Page (${props?.mode}-mode)` });
  try{window.tidioChatApi.hide()}catch{}

  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const history = useHistory();
  const urlUsername = props.match.params.user;
  const urlEventType = props.match.params.event;
  const urlBookingId = props.match.params.bookingid;
  const searchParams = new URLSearchParams(props.location.search);
  const mode = props?.mode;
  const [bookingStep,setBookingStep] = useState((isIframe() && searchParams.get("step")) ? searchParams.get("step") : 1);
  const [infoResponseStatus,setInfoResponseStatus] = useState(0);
  const [loadingInfo,setLoadingInfo] = useState(false);
  const [stylingParams,setStylingParams] = useState({})
  const storeDetails = useCreateBookingStore(state => state.details);
  const storeCalendar = useCreateBookingStore(state => state.calendar);
  const storeGuest = useCreateBookingStore(state => state.guest);
  const setDetails = useCreateBookingStore(state => state.setDetails);
  const setCalendar = useCreateBookingStore(state => state.setCalendar);
  const setGuestDay = useCreateBookingStore(state => state.setGuestDay);
  const setGuestTime = useCreateBookingStore(state => state.setGuestTime);
  const setGuestTimezone = useCreateBookingStore(state => state.setGuestTimezone);

  useEffect(() =>{
    // styling state setter with key and value
    // this state will be passed to components
    const setKeyValueStyle = (_key,_value) => {
      setStylingParams(prevState => ({
        ...prevState,
        [_key]:_value
      }));
    }

    // styling params from url
    const backgroundPageStyle = searchParams.get("background");
    const backgroundComponentsStyle = searchParams.get("components-bg");
    const textColorComponentsStyle = searchParams.get("components-text-color");
    const accentComponentsStyle = searchParams.get("components-accent-color");

    // --- STYLING OPTIONS ---
    // valid only when the page is in Iframe
    if(isIframe()){
      if(backgroundPageStyle){document.getElementById("root").style.background = `${backgroundPageStyle}`}
      if(backgroundComponentsStyle){
        setKeyValueStyle("details-bg",backgroundComponentsStyle)
        setKeyValueStyle("calendar-navigation-bg",backgroundComponentsStyle)
        setKeyValueStyle("calendar-day-bg",backgroundComponentsStyle)
        setKeyValueStyle("timezone-bg",backgroundComponentsStyle)
        setKeyValueStyle("timeslot-bg",backgroundComponentsStyle)
        setKeyValueStyle("summary-bg",backgroundComponentsStyle)
      }
      if(textColorComponentsStyle){
        setKeyValueStyle("details-text-color",textColorComponentsStyle)
        setKeyValueStyle("details-schedule-btn-text-color",textColorComponentsStyle)
        setKeyValueStyle("calendar-date-text-color",textColorComponentsStyle)
        setKeyValueStyle("calendar-navigation-text-color",textColorComponentsStyle)
        setKeyValueStyle("calendar-weekdays-text-color",textColorComponentsStyle)
        setKeyValueStyle("calendar-day-text-color",textColorComponentsStyle)
        setKeyValueStyle("calendar-active-day-text-color",textColorComponentsStyle)
        setKeyValueStyle("timezone-text-color",textColorComponentsStyle)
        setKeyValueStyle("timeslot-text-color",textColorComponentsStyle)
        setKeyValueStyle("summary-text-color",textColorComponentsStyle)
      }
      if(accentComponentsStyle){
        setKeyValueStyle("details-schedule-btn-bg",accentComponentsStyle)
        setKeyValueStyle("calendar-active-day-bg",accentComponentsStyle)
        setKeyValueStyle("calendar-active-day-border",accentComponentsStyle)
        setKeyValueStyle("timezone-active-bg",accentComponentsStyle)
        setKeyValueStyle("timeslot-active-text-color",accentComponentsStyle)
        setKeyValueStyle("summary-active-text-color",accentComponentsStyle)
      }
    }

    const getBookingInfo = (_username,_event) => {
      let body = {
        ownerUsername: _username,
        eventKey : _event,
      }
      if(isIframe()){body.isEmbed = true}
      if(searchParams.get("paid")){body.paid = searchParams.get("paid")}
      setLoadingInfo(true);
      be.get('jubilant-booking','/booking/details',body,false,true)
        .then(response => {
          // save response to state
          console.info("[booking][getBookingInfo] Success:");
          console.log(response);
          setInfoResponseStatus(200);
          setLoadingInfo(false);
          setDetails(response);
        })
        .catch(error => {
          if(error?.response?.status === 302){
            // redirect the guest to owner public page if 302
            history.push({pathname:`/${_username}`})
          }else{
            console.info("[booking][getBookingInfo] Error:");
            console.error(error);
            setInfoResponseStatus(error?.response?.status);
            setLoadingInfo(false);
            toast.error(`Error Fetching Event Details`)
          }
        })
    }

    const getBookedInfo = async (_id) => {
      // this is needed to avoid summary stage when rescheduling
      // the summary triggers only when the time is set
      // it can happen to visit reschedule page with 'time' state not empty
      // (not empty time state means that i have a previous booking state)
      mode === "reschedule" && setGuestTime("")

      const body = {
        bookingId : _id
      }

      setLoadingInfo(true);
      await be.get('jubilant-booking','/booked',body,false,true)
        .then(response => {
          // save response to state
          console.info("[booking][getRescheduleInfo] Success:");
          console.log(response);
          setInfoResponseStatus(200);
          setLoadingInfo(false);
          setDetails(response);
          mode === "delete" && setGuestTime(response?.booked?.bookingDateTime)
        })
        .catch(error => {
          console.info("[booking][getRescheduleInfo] Error:");
          console.error(error);
          setInfoResponseStatus(error?.response?.status);
          setLoadingInfo(false);
          toast.error(`Error Fetching Event Details`)
        })
    }
    (mode === "reschedule" || mode === "delete") ? getBookedInfo(urlBookingId) : getBookingInfo(urlUsername,urlEventType)
  },[props.history,mode,urlUsername,urlEventType,urlBookingId,setDetails])
  
  const stageRenderer = (_stage,_storeGuest,_loading,_mode) => {
    switch(_stage){
      case 'details':
        if(isDesktop){ return true }
        else if(isMobile && bookingStep === 1){ return true }
        else{ return false }
      case 'date':
        if(isDesktop && (_storeGuest?.day || !_loading) && !_storeGuest.time){ return true }
        else if(isMobile && bookingStep === 2){ return true}
        else{return false}
      case 'time':
        if(isDesktop && (_storeGuest?.day || !_loading) && !_storeGuest.time){ return true }
        else if(isMobile && bookingStep === 3){ return true}
        else{return false}
      case 'summary':
        if(isDesktop && _storeGuest?.time) { return true }
        else if(isMobile && bookingStep === 4){ return true }
        else{ return false }
      default:
        return false;
    }
  }

  return(
    <div className="booking-container">
      {stageRenderer('details',null,null,mode) &&
        <div className="details-container">
          <Details
            event={storeDetails?.event}
            owner={storeDetails?.owner}
            payment={storeDetails?.payment}
            loading={loadingInfo}
            setBookingStep={setBookingStep}
            stylingParams={stylingParams}
          />
        </div>
      }
      <div className="datetime-container" >

        {stageRenderer('date',storeGuest,loadingInfo,mode) &&
          <div className="date-timezone-container">
            <div className="date-picker-container">
              <DatePicker
                username={storeDetails?.owner?.name}
                eventType={urlEventType || storeDetails?.event?.key}
                mode={mode}
                setBookingStep={setBookingStep}
                stylingParams={stylingParams}
              />

            </div>

            <div className="timezone-picker-container">
              <TimezonePicker mode={mode} stylingParams={stylingParams}/>
            </div>
          </div>
        }

        {stageRenderer('time',storeGuest,loadingInfo,mode) &&
          <div className="time-picker-container">
            <TimePicker
              username={storeDetails?.owner?.name}
              eventType={urlEventType || storeDetails?.event?.key}
              mode={mode}
              setBookingStep={setBookingStep}
              stylingParams={stylingParams}
            />
          </div>
        }

      </div>
      {stageRenderer('summary',storeGuest,mode) &&
        <div className="summary-container">
          <Summary
            username={storeDetails?.owner?.name}
            eventType={urlEventType || storeDetails?.event?.key}
            mode={mode}
            urlBookingId={urlBookingId}
            setBookingStep={setBookingStep}
            stylingParams={stylingParams}
          />
        </div>
      }
      <Toaster/>
    </div>
  );
}

export default Booking;
