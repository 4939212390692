import { useState } from 'react';
import Overview from './Overview';
import Contacts from './Contacts/Contacts';

function Leads(){
    const [showPlan,setShowPlan] = useState([]);

    return(
        <>
            <Overview showPlan={showPlan} setShowPlan={setShowPlan}/>
            <Contacts showPlan={showPlan}/>
        </>
    );
}
export default Leads;
